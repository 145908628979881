// extracted by mini-css-extract-plugin
export var allcontent = "index-module--allcontent--++ffi";
export var bottom_detail = "index-module--bottom_detail--fQSsf";
export var bottom_title = "index-module--bottom_title--avVMl";
export var intab_activeitem = "index-module--intab_activeitem--WJ3BT";
export var intab_cotent = "index-module--intab_cotent--651al";
export var intab_detaillist_address = "index-module--intab_detaillist_address--d4Wgd";
export var intab_detaillist_expand = "index-module--intab_detaillist_expand--yyfLc";
export var intab_detaillist_li = "index-module--intab_detaillist_li--fTRO4";
export var intab_detaillist_libottom = "index-module--intab_detaillist_libottom--44i93";
export var intab_detaillist_litop = "index-module--intab_detaillist_litop--Nl-9S";
export var intab_detaillist_text = "index-module--intab_detaillist_text--QReRk";
export var intab_item = "index-module--intab_item--qpZv8";
export var intab_liactive = "index-module--intab_liactive--zBoBm";
export var intab_main_content = "index-module--intab_main_content--XqoOO";
export var intab_main_detaillist = "index-module--intab_main_detaillist--kDrpy";
export var intab_main_navleft = "index-module--intab_main_navleft--BrZLz";
export var intab_title = "index-module--intab_title--mFrNJ";