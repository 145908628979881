import React from 'react'
import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Banner from 'components/About/Banner'
import Nav from 'components/About/Nav'
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'
import InformationDisplay from 'components/About/InformationDisplay'

import 'styles/base.less'
import * as styles from './index.module.less'

const Joinus: React.FC = (props) => {
  const demoUrl = '/demo/demo'
  const paradeUrl = '/parade/sa'

  return (
    <Layout {...props}>
      <JoinusState demoUrl={demoUrl} paradeUrl={paradeUrl} />
    </Layout>
  )
}

interface JoinusStateProps {
  demoUrl?: string
  paradeUrl?: string
}
const JoinusState: React.FC<JoinusStateProps> = (props) => {
  const { demoUrl, paradeUrl } = props

  return (
    <>
      <Seo
        title="神策數據 | 官方招聘 大數據用戶行為分析產品"
        description="神策數據（官網）招聘，系統研發工程師，算法工程師，數據分析師、咨詢顧問等崗位，簡歷投遞 hr@sensorsdata.cn"
        keywords="神策數據招聘,神策崗位,神策待遇,神策招人"
        saTitle=""
      />
      <main className={classnames(styles.Joinus)}>
        <Banner
          title="一起做事，一起成長"
          desc="這裡有完全扁平的管理，這裡有一群專注做事的夥伴，這裡有開放的溝通文化，這裡有輕鬆舒適的辦公環境，這裡有我們，這裡歡迎你。"
        />
        <Nav
          list={[
            {
              name: '公司介紹',
              url: '/about/aboutus.html',
              isActive: false,
            },
            {
              name: '加入我們',
              url: '/about/joinus.html',
              isActive: true,
            },
          ]}
        />
        <SectionContent title="現招職位">
          <InformationDisplay
            className={styles.InformationDisplay}
            informationList={[
              {
                title: '招募',
                topText: '履歷投遞信箱',
                topHref: 'mailto:wudi@sensorsdata.com',
                topHrefText: 'wudi@sensorsdata.com',
                lefNav: [
                  {
                    navName: '查看全部',
                    lefId: 'all',
                  },
                  {
                    navName: '專業服務',
                    lefId: '2',
                  },
                  {
                    navName: '技術團隊',
                    lefId: '3',
                  },
                ],
                allDetail: [
                  {
                    detailName: '客戶成功經理',
                    lefId: '2',
                    address: ['台灣'],
                    require: [
                      {
                        requireTitle: '工作職掌：',
                        requireDetail: [
                          '1、梳理客戶需求，協助客戶接入並正常使用神策數據產品；',
                          '2、調動公司內資源，解決客戶在產品使用中的問題；',
                          '3、針對客戶業務場景，提供對應的數據解決方案，並與客戶一起落地；',
                          '4、從客戶需求中提煉產品需求及解決方案，供產品優化採用與經驗累積分享。',
                        ],
                      },
                      {
                        requireTitle: '職務要求：',
                        requireDetail: [
                          '1、大學及以上學歷，理工科等相關專業；',
                          '2、三年以上專案管理經驗或客戶服務經驗；',
                          '3、與人溝通有耐心，抗壓能力強；',
                          '4、良好的文檔撰寫能力，善於總結工作中的經驗；',
                          '5、有數據工程項目或數據分析經驗者優先；',
                          '6、對大數據感興趣，思路清晰、協作能力強、有快速學習的能力。',
                        ],
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        </SectionContent>
        <PageBottomCard
          title="數據驅動，從現在開始！"
          desc="立即註冊，和神策數據分析師一起開啓數據驅動之旅！"
          leftButtonText="體驗 Demo"
          leftButtonHref={demoUrl}
          rightButtonText="預約上門演示"
          rightButtonHref={paradeUrl}
        />
      </main>
    </>
  )
}

export default Joinus
