import React, { useEffect, useImperativeHandle, useState } from 'react';
import classnames from 'classnames';
import * as styles from './index.module.less';

interface InformationDisplayProps {
    className?: string;
    informationList?: any;
    inref?: any;
}
const InformationDisplay: React.FC<InformationDisplayProps> = ({
    informationList,
    inref,
    className
}) => {
    const [curIndex, setCurIndex] = useState(0);
    const [lefNavCurrIndex, setLefNavCurrIndex] = useState(0);
    const [curData, setCurData] = useState(informationList[0] || {});
    const [lefNavCurrId, setLefNavCurrId] = useState(informationList[0].lefNav[0].lefId);
    const [changeallDeatil, setChangeallDeatil] = useState(informationList[0]['allDetail']);
    const [oldNavIdObj, setOldNavIdObj] = useState<any>({
        0: {
            idIndex: 0,
            idValue: informationList[0].lefNav[0].lefId
        }
    });

    const itemClick = (item: any, index: number) => {
        setCurIndex(index);
        setCurData(item);
        let arr = [...changeallDeatil];
        arr.forEach((item) => {
            item['expend'] = false;
        });
        setChangeallDeatil([...arr]);
        if (Object.keys(oldNavIdObj).indexOf(index.toString()) === -1) {
            setLefNavCurrIndex(0);
            let lefId;
            if (item.lefNav && item.lefNav.length > 0) {
                lefId = item.lefNav[0].lefId;
            }
            setLefNavCurrId(lefId);
            changeAllId(index, 0, lefId);
        } else {
            Object.keys(oldNavIdObj).forEach((key) => {
                if (Number(key) === Number(index)) {
                    setLefNavCurrIndex(oldNavIdObj[key].idIndex);
                    setLefNavCurrId(oldNavIdObj[key].idValue);
                }
            });
        }
    };

    const lefNavClick = (item: any, index: any) => {
        setLefNavCurrIndex(index);
        setLefNavCurrId(item);
        changeAllId(curIndex, index, item);
    };

    const changeAllId = (key: any, index: any, value: any) => {
        setOldNavIdObj({
            ...oldNavIdObj,
            [key]: {
                idIndex: index,
                idValue: value
            }
        });
    };

    const detailClick = (el: any, index: number) => {
        let arr = [...changeallDeatil];
        if (arr[index]['expend']) {
            arr[index]['expend'] = false;
        } else {
            arr[index]['expend'] = true;
        }
        setChangeallDeatil([...arr]);
    };

    useImperativeHandle(inref, () => ({
        changeTab: (index: any) => {
            setCurIndex(index);
        }
    }));

    useEffect(() => {
        setChangeallDeatil(curData['allDetail']);
    }, []);

    const liHtml = (el: any, idx: any) => {
        return (
            <li
                onClick={() => detailClick(el, idx)}
                className={classnames(styles.intab_detaillist_li, 'sd-font-16')}
            >
                <div className={styles.intab_detaillist_litop}>
                    <div className={styles.intab_detaillist_text}>
                        <img src={require("assets/images/joinus/post.svg").default} />
                        <div>{el.detailName}</div>
                    </div>
                    <div
                        className={classnames(styles.intab_detaillist_address, {
                            [styles.intab_detaillist_expand]: el.expend
                        })}
                    >
                        <img src={require("assets/images/joinus/position.svg").default} />
                        {(el['address'] || []).map((el: any, idx: any) => {
                            return (
                                <span key={idx}>
                                    {idx > 0 ? ',' : ''} {el}
                                </span>
                            );
                        })}
                    </div>
                </div>
                {el.expend ? (
                    <ul className={classnames(styles.intab_detaillist_libottom)}>
                        {(el['require'] || []).map((item: any, deta_index: any) => {
                            return (
                                <li key={deta_index}>
                                    <div className={classnames(styles.bottom_title, 'sd-font-18')}>
                                        {item.requireTitle}
                                    </div>
                                    {(item['requireDetail'] || []).map((item: any, index: any) => {
                                        return (
                                            <div key={index} className={styles.bottom_detail}>
                                                {item}
                                            </div>
                                        );
                                    })}
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </li>
        );
    };

    return (
        <div className={classnames(styles.allcontent, className)}>
            <div className={classnames(styles.intab_title, 'sd-font-18')}>
                {curData['topImg'] ? (
                    curData['topHref'] ? (
                        <a href={curData.topHref}>
                            <img src={curData.topImg} />
                        </a>
                    ) : (
                        <img src={curData.topImg} />
                    )
                ) : (
                    <div>
                        <span>{curData.topText}</span>
                        {curData['topHref'] ? (
                            <a href={curData.topHref}>{curData.topHrefText}</a>
                        ) : (
                            <span>{curData.topHrefText}</span>
                        )}
                    </div>
                )}
            </div>
            {/*
                横向导航栏
            */}
            <ul className={classnames(styles.intab_cotent, 'sd-font-18')}>
                {informationList.map((item: any, index: any) => {
                    return (
                        <li
                            key={index}
                            onClick={() => itemClick(item, index)}
                            className={classnames(styles.intab_item, {
                                [styles.intab_activeitem]: index === curIndex
                            })}
                        >
                            {item.title}
                        </li>
                    );
                })}
            </ul>
            <div className={styles.intab_main_content}>
                {/*
                    左侧导航栏
                */}
                <ul className={classnames(styles.intab_main_navleft, 'sd-font-18')}>
                    {(curData['lefNav'] || []).map((el: any, idx: any) => {
                        return (
                            <li
                                key={idx}
                                onClick={() => lefNavClick(el.lefId, idx)}
                                className={classnames({
                                    [styles.intab_liactive]: idx === lefNavCurrIndex
                                })}
                            >
                                {el.navName}
                            </li>
                        );
                    })}
                </ul>
                {/*
                    右侧信息栏
                */}
                <ul className={classnames(styles.intab_main_detaillist, 'sd-font-18')}>
                    {(changeallDeatil || []).map((el: any, idx: any) => {
                        return (
                            <div key={idx}>
                                {lefNavCurrId === 'all' ? liHtml(el, idx) : null}
                                {el['lefId'] === lefNavCurrId && lefNavCurrId !== 'all'
                                    ? liHtml(el, idx)
                                    : null}
                            </div>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default InformationDisplay;
